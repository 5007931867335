.flexCenterSection {
  background-color: #fcfdef;
  padding: 40px 0;
}

@media screen and (max-width: 1036px) {
  .flexCenterSection {
    padding: 0 0 40px 0;
  }
}

.bannerPosition {
  margin-bottom: -8px;
}
